var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"case"},[_c('div',{staticClass:"caseBox"},[_c('u-animate-container',[_c('u-animate',{attrs:{"name":"zoomIn","delay":"0s","duration":"0.5s","iteration":1,"offset":0,"animateClass":"animated","begin":false}},[_c('div',{staticClass:"titleBox"},[_c('p',{staticClass:"title1"},[_vm._v("案例展示")]),_c('span',{staticClass:"title1-line"}),_c('p',{staticClass:"title2"},[_vm._v("Cases")])])])],1),_c('el-row',{attrs:{"gutter":20,"justify":"space-around"}},_vm._l((_vm.robotCase),function(item,idx){return _c('el-col',{key:idx,class:item.type==='horizontal'?'video_card_horizontal':'video_card',attrs:{"xs":item.type==='horizontal'?24:12,"sm":12,"md":12,"lg":item.type==='horizontal'?12:6,"xl":item.type==='horizontal'?12:6}},[_c('el-card',{attrs:{"body-style":{ padding: '1px' }}},[_c('video-player',{ref:"videoPlayer",refInFor:true,staticClass:"video-player vjs-custom-skin video-self",attrs:{"playsinline":true,"webkit-playsinline":"true","x5-playsinline":"true","x-webkit-airplay":"true","x5-video-player-type":"h5","x5-video-player-fullscreen":"true","x5-video-ignore-metadata":"true","options":{
           autoplay: false,
           muted: false,
           loop: false,
           preload: 'auto',
           language: 'zh-CN',
           aspectRatio: item.type==='horizontal'?'16:9':'9:16',
           fluid: true,
           hls: true,
           notSupportedMessage: '此视频暂无法播放，请稍后再试',
           controlBar: {
              timeDivider: false,
              durationDisplay: false,
              remainingTimeDisplay: false,
              fullscreenToggle: false // 全屏按钮
            },
           sources: [
              {
                type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                src: item.url // url地址
              }
          ]
        }}}),_c('div',{staticClass:"card_info"},[_c('div',{staticClass:"video_title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"video_brand"},[_vm._v(_vm._s(item.brand))])])],1)],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }