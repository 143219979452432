<template>
  <div class="case">
    <div class="caseBox">
      <u-animate-container>
        <u-animate name="zoomIn" delay="0s" duration="0.5s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <div class="titleBox">
            <p class="title1">案例展示</p>
            <span class="title1-line"></span>
            <p class="title2">Cases</p>
          </div>
        </u-animate>
      </u-animate-container>
      <el-row :gutter="20" justify="space-around">
        <el-col :class="item.type==='horizontal'?'video_card_horizontal':'video_card'" :xs="item.type==='horizontal'?24:12" :sm="12" :md="12" :lg="item.type==='horizontal'?12:6" :xl="item.type==='horizontal'?12:6" v-for="(item,idx) in robotCase" :key="idx">
          <el-card :body-style="{ padding: '1px' }">
            <video-player ref="videoPlayer" class="video-player vjs-custom-skin video-self" :playsinline="true" webkit-playsinline='true' x5-playsinline="true" x-webkit-airplay="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true" :options="{
             autoplay: false,
             muted: false,
             loop: false,
             preload: 'auto',
             language: 'zh-CN',
             aspectRatio: item.type==='horizontal'?'16:9':'9:16',
             fluid: true,
             hls: true,
             notSupportedMessage: '此视频暂无法播放，请稍后再试',
             controlBar: {
                timeDivider: false,
                durationDisplay: false,
                remainingTimeDisplay: false,
                fullscreenToggle: false // 全屏按钮
              },
             sources: [
                {
                  type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                  src: item.url // url地址
                }
            ]
          }"/>
            <div class="card_info">
              <div class="video_title">{{ item.title }}</div>
              <div class="video_brand">{{ item.brand }}</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { robotCase } from "@/assets/js/fixed_data"

export default {
  name: "case",
  data() {
    return {
      robotCase: robotCase
    }
  }
}
</script>

<style lang="less" scoped>
.case {
  padding-bottom: 1rem;
}

.caseBox {
  width: 12rem;
  margin: 0 auto;
}

.titleBox {
  text-align: center;
  padding: .5rem 0 .43rem 0;
  font-size: 0;
  color: #333333;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }

  .title1 {
    text-align: center;
    font-size: .32rem;
    font-weight: 500;
  }

  .title1-line {
    display: block;
    margin: 0 auto;
    width: 70px;
    height: 3px;
    background-color: #5b5af3;
    border-radius: 20px;
    transform: rotateY(90deg);
    transition: all 1s;
  }

  &:hover .title1-line {
    transform: rotateY(0deg);
  }

  .title2 {
    font-size: 21px;
    font-family: Impact, Charcoal, sans-serif;
    font-weight: normal;
    color: #7776ff33;
    line-height: 31px;
    letter-spacing: 3px;
    font-style: italic;
    margin-top: 0.06rem;
    display: inline-block;
  }
}

.video_card {
  width: auto;
  margin-top: 10px;

  .video-self {
    width: 210px;
    height: 431px;

    /deep/ .video-js.vjs-fluid {
      height: 100%;
    }
  }
}

.video_card_horizontal {
  width: auto;
  margin-top: 10px;

  .video-self {
    width: 260px;
    height: 172px;

    /deep/ .video-js.vjs-fluid {
      height: 100%;
    }
  }
}

.card_info {
  padding: 14px;

  .video_title {
    font-size: 16px
  }

  .video_brand {
    font-size: 14px;
    margin-top: 4px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .caseBox {
    width: 94%;
  }

  .video_card_horizontal {
    width: 47%;
    margin-top: 10px;
    .video-self {
      width: 100%;
      height: 100%;

      /deep/ .video-js.vjs-fluid {
        height: 100%;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .caseBox {
    width: calc(100% - .24rem);
  }

  .titleBox {
    padding: 0.3rem 0 0.1rem;

    .title1 {
      font-size: 20px;
    }

    .title2 {
      margin-top: 0;
      font-size: 14px;
      letter-spacing: 2px;
    }
  }

  .video_card {
    width: 50%;
    .video-self {
      width: 100%;
      height: 100%;

      /deep/ .video-js.vjs-fluid {
        height: 100%;
      }
    }
  }

  .video_card_horizontal {
    width: 100%;
    margin-top: 10px;
    .video-self {
      width: 100%;
      height: 100%;

      /deep/ .video-js.vjs-fluid {
        height: 100%;
      }
    }
  }
}
</style>
