<template>
  <div>
    <page-guide :options="options" :slogan="slogan" :pc-image="backgroundImage.pcImage" :mobile-image="backgroundImage.mobileImage"></page-guide>
    <introduction :productIntroduction="productIntroduction" type="right"></introduction>
    <help-spread></help-spread>
    <case></case>
    <m-footer></m-footer>
  </div>
</template>

<script>
import pageGuide from '@/components/page-guide';
import introduction from "@/components/introduction";
import helpSpread from "./help-spread";
import Case from './case'
import footer from "@/components/footer";
import { ProductIntroduction } from "@/assets/js/fixed_data";

export default {
  name: "index",
  components: {
    pageGuide,
    introduction,
    helpSpread,
    Case,
    'm-footer': footer
  },
  data() {
    return {
      options: [{
        content: 'Magics',
        animate: 'fadeIn',
        delay: '0s'
      }, {
        content: '智能',
        animate: 'fadeIn',
        delay: '0.8s'
      }, {
        content: '服务',
        animate: 'fadeIn',
        delay: '0.5s'
      }, {
        content: '机器人',
        animate: 'fadeIn',
        delay: '0.7s'
      }],
      slogan: '千人千面VIP专属服务',
      backgroundImage: {
        pcImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/bg1.png',
        mobileImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/01A.png'
      },
      productIntroduction: ProductIntroduction.intelligentRobot
    }
  }
}
</script>

<style scoped>

</style>
