<template>
  <div class="vcBox">
    <div class="vcBox1">
      <p class="vcTitle">助力品牌实现视觉化/人格化创意传播</p>
      <div class="vcCard">

        <div class="cardItem" v-for="(item,idx) in assistanceModeList" :key="idx" @mouseenter="showPlay(idx)" @mouseleave="showPlay(null)">
          <u-animate-container>
            <u-animate :name="item.animate" delay="0s" duration="1.5s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
              <div @click.stop="cardPlayEve(item)" :class="hoverIdx===idx?'hoverMove':''">
                <img :src="item.card" alt="">
                <div class="cardPlay">
                  <div class="playBlack"></div>
                  <img class="playicon" src="@/assets/image/playicon.png" alt="">
                </div>
              </div>

            </u-animate>
          </u-animate-container>
          <p class="title">{{ item.title }}</p>
          <span class="line"></span>
          <p class="text">{{ item.text }}</p>
        </div>

      </div>
    </div>

    <el-dialog :visible.sync="videoDialogVisible" :width="width" :top="top" :before-close="beforeClose" :modal-append-to-body="false" center>
      <video-player ref="videoPlayer" class="video-player vjs-custom-skin video-self" style="object-fit:fill" :playsinline="true" webkit-playsinline='true' x5-playsinline="true" x-webkit-airplay="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true" :options="playerOptions"/>
    </el-dialog>
  </div>
</template>

<script>
import { assistanceModeList } from '@/assets/js/fixed_data'
import { mapGetters } from "vuex";

export default {
  name: "help-spread",
  data() {
    return {
      assistanceModeList: assistanceModeList,
      hoverIdx: null,
      videoDialogVisible: false,
      width: this.isMobile ? '100%' : '720px',
      top: this.isMobile ? '25vh' : '15vh',
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: ''
          }
        ],
        hls: true,
        width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
    }
  },
  computed: {
    ...mapGetters(["isMobile", 'clientWidth']),
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    showPlay(idx) {
      this.hoverIdx = idx
    },
    cardPlayEve(item) {
      this.videoDialogVisible = true;
      this.playerOptions.sources[0].src = item.video;
      this.playerOptions.autoplay = true;
      // this.player.play();
    },

    beforeClose(done) {
      this.player.pause()
      done();
    }
  }
}
</script>

<style lang="less" scoped>
.vcBox {
  width: 100%;
  height: 7.1rem;
  background: #F8F8FF;

  .vcBox1 {
    height: 100%;
    margin: 0 auto;
    width: 1200px;
    text-align: center;

    .vcTitle {
      padding: .9rem 0 1.4rem;
      font-size: 28px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
    }

    .vcCard {
      font-size: 0;

      .cardItem {
        width: 20%;
        display: inline-block;
        text-align: center;
        font-size: 0;
        position: relative;

        .cardPlay {
          width: 129px;
          height: 129px;
          position: absolute;
          margin: 0 auto;
          border-radius: 50%;
          top: 0;
          left: 46px;
          overflow: hidden;
          opacity: 0;
          transition: all .5s;

          .playBlack {
            width: 100%;
            height: 100%;
            background-color: #2b333f;
            opacity: 0.5;
          }

          .playicon {
            position: absolute;
            z-index: 111;
            top: 42px;
            left: 39px;
          }
        }

        /*&:hover .cardPlay{*/
        /*  opacity: 1;*/
        /*  cursor: pointer;*/
        /*}*/

        .title {
          margin-top: 48px;
          height: 25px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 25px;
        }

        .line {
          width: 70px;
          height: 2px;
          background: rgba(119, 118, 255, 1);
          border-radius: 1px;
          display: inline-block;
          margin: 4px 0 6px;
        }

        .text {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 20px;
        }
      }

      .hoverMove {
        &:hover .cardPlay {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}


/deep/ .el-dialog__body {
  padding: 0 !important;
}

/deep/ .el-dialog__header {
  padding: 0 !important;
}

/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #c3c9d6;
  position: absolute;
  right: -120px;
  top: -45px;
  font-size: 34px;
}


/deep/ .vjs-big-play-button {
  font-size: 20px !important;
  border-radius: 50%;
  height: 20px;
  width: 42px;
}

/deep/ .el-carousel__button {
  height: 16px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .vcBox1 {
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .vcBox {
    height: 1000px;

    .vcBox1 {
      width: 100%;

      .vcTitle {
        padding: .5rem 0;
        font-size: 20px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }

      .vcCard {
        font-size: 0;

        .cardItem {
          margin-top: 0.3rem;
          width: 50%;

          .cardPlay {
            left: calc(50% - 64px);
          }

        }
      }
    }
  }

  .player2Dialog {
    background: transparent !important;
  }

  /deep/ .el-dialog--center .el-dialog__footer {
    padding: 0 !important;
  }

  .dialogFooter {
    background-color: transparent;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: #c3c9d6;
    position: absolute;
    right: -0px;
    top: -80px;
    font-size: 34px;
  }
}
</style>
